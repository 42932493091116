<template>
  <div class="device-detail">
    <div>
      <page-content :title="device.nickname">
      </page-content>
    </div>
    <div class="body">

      <div v-show="tabKey=='status'">
        <div class="batch-control">

          <div class="item clickable" v-for="item in urgentList" @click="clickUrgent(item)">
            <img :src="item.icon"/>
            <div>{{item.value==1 ? item.selectedName : item.name}}</div>
          </div>
        </div>

        <PoolParas :paraArray="paraList"></PoolParas>
        <PoolSwitch :switchArray="switchList" @controlItems="controlItems" :valueMap="valueMap"></PoolSwitch>
      </div>

      <div v-show="tabKey=='purification'">
        <PoolParas :paraArray="paraList"></PoolParas>
        <PoolPurification :purificationData="purificationData" @control="control" @controlItems="controlItems"></PoolPurification>
      </div>

      <PoolSandTank v-show="tabKey=='sand-tank'" :sandTankArray="sandTankList" :valueMap="valueMap" @control="control"></PoolSandTank>
      <PoolTimingControl v-show="tabKey=='timing-control'" :timingArray="timingList" @control="control"></PoolTimingControl>
      <PoolSystemControl v-show="tabKey=='system-control'" :systemControl="systemControl" @control="control" @controlItems="controlItems"></PoolSystemControl>
      <PoolCalibration v-show="tabKey=='calibration'" :calibrationArray="calibrationList" @control="control" @controlItems="controlItems"></PoolCalibration>
      <PoolHistoryData v-show="tabKey=='history-data'" :deviceId="deviceId" :paraList="paraList"></PoolHistoryData>
      <PoolLockDevice v-show="tabKey=='device-lock'" :model="systemControl"  @control="control" @controlItems="controlItems"></PoolLockDevice>
      <PoolWaterAlarm v-show="tabKey=='water-alarm'" :systemControl="systemControl"></PoolWaterAlarm>


    </div>


    <PoolBottomBar @onTabChange="onTabChange" :tabData="tabData" :tabKey="tabKey" :device="device"></PoolBottomBar>

    <DeviceErrorModal :device="device" :valueMap="valueMap"></DeviceErrorModal>
    <DeviceWarningModal ref="warningModal"></DeviceWarningModal>

    <DeviceEdit ref="deviceEdit" @success="getDeviceInfo"></DeviceEdit>


  </div>
</template>
<script>
import BaseDeviceView from "./BaseDeviceView";
import DeviceEdit from "./DeviceEdit";

import PoolBottomBar from "@/views/device/_components/PoolBottomBar";
import PoolParas from "@/views/device/_components/PoolParas";
import PoolSwitch from "@/views/device/_components/PoolSwitch";
import PoolCalibration from "@/views/device/_components/PoolCalibration";
import PoolSandTank from "@/views/device/_components/PoolSandTank";
import PoolTimingControl from "@/views/device/_components/PoolTimingControl";
import PoolSystemControl from "@/views/device/_components/PoolSystemControl";
import PoolPurification from "@/views/device/_components/PoolPurification";
import PoolHistoryData from "@/views/device/_components/PoolHistoryData";
import PoolLockDevice from "@/views/device/_components/PoolLockDevice";
import PoolWaterAlarm from "../device/errorlog/ErrorLog";
import DeviceErrorModal from "@/views/device/_components/DeviceErrorModal";
import DeviceWarningModal from "@/views/device/_components/DeviceWarningModal";
import YongleParaUtil from "./paras/YongleParaUtil";
export default {
  extends: BaseDeviceView,
  components: {
    DeviceEdit,
    PoolParas,
    PoolSwitch,
    PoolCalibration,
    PoolSandTank,
    PoolTimingControl,
    PoolSystemControl,
    PoolPurification,
    PoolHistoryData,
    PoolLockDevice,
    PoolWaterAlarm,
    DeviceErrorModal,
    DeviceWarningModal,
    PoolBottomBar
  },
  data() {
    return {
      tabKey: "status",
    }
  },
  computed: {
    deviceFunction(){
      return YongleParaUtil.getParas(this.device.model)
    },
    tabData(){
      let arr = []
      if(this.switchList.length>0){
        arr.push({
          icon: require("@/assets/tab-status.png"),
          selectedIcon: require("@/assets/tab-status-selected.png"),
          name: "泳池状态",
          key: "status"
        })
      }
      if(this.purificationData.timing.length>0){
        arr.push({
          icon: require("@/assets/tab-purification.png"),
          selectedIcon: require("@/assets/tab-purification-selected.png"),
          name: "水质净化",
          key: "purification"
        })
      }
      if(this.sandTankList.length>0){
        arr.push({
          icon: require("@/assets/tab-sand-tank.png"),
          selectedIcon: require("@/assets/tab-sand-tank-selected.png"),
          name: "砂缸清洗",
          key: "sand-tank"
        })
      }
      if(this.timingList.length>0){
        arr.push({
          icon: require("@/assets/tab-timing.png"),
          selectedIcon: require("@/assets/tab-timing-selected.png"),
          name: "定时控制",
          key: "timing-control"
        })
      }
      if(true){
        arr.push({
          icon: require("@/assets/tab-system.png"),
          selectedIcon: require("@/assets/tab-system-selected.png"),
          name: "系统设置",
          key: "system-control"
        })
      }
      if(this.calibrationList.length>0){
        arr.push({
          icon: require("@/assets/tab-calibration.png"),
          selectedIcon: require("@/assets/tab-calibration-selected.png"),
          name: "探头校准",
          key: "calibration"
        })
      }
      if(this.paraList.length>0){
        arr.push({
          icon: require("@/assets/tab-history.png"),
          selectedIcon: require("@/assets/tab-history-selected.png"),
          name: "历史数据",
          key: "history-data"
        })
      }

      //判断是否有设备饮水机权限
      if(this.$store.state.account.permissions.includes("device:lock")){
        arr.push({
          icon: require("@/assets/tab-device-lock.png"),
          selectedIcon: require("@/assets/tab-device-lock-selected.png"),
          name: "锁定设备",
          key: "device-lock"
        })
      }
      if(true){
        arr.push({
          icon: require("@/assets/tab-water-alarm.png"),
          selectedIcon: require("@/assets/tab-water-alarm-selected.png"),
          name: "水质报警",
          key: "water-alarm"
        })
      }
      return arr
    },
    paraList(){
      let list = JSON.parse(JSON.stringify(this.deviceFunction.paraList))
      list.forEach(item=>{
        item.value = this.valueMap[item.dataId]
      })
      return list
    },
    switchList(){
      let list = JSON.parse(JSON.stringify(this.deviceFunction.switchList))
      list.forEach(item=>{
        // TODO 水泵开关要从读地址获取
        // item.value = this.valueMap[item.writeDataId]
        item.value = this.valueMap[item.readDataId]
      })
      return list
    },
    purificationData(){
      let purification = JSON.parse(JSON.stringify(this.deviceFunction.purification))

      purification.timing.forEach(item=>{
        for(let key in item){
          if(item[key].dataId){
            item[key].value=this.valueMap[item[key].dataId]
          }
          if(item[key].statusDataId){
            item[key].statusValue=this.valueMap[item[key].statusDataId]
          }
        }
      })

      purification.mode.forEach(item=>{
        item.value=this.valueMap[item.dataId] || 0
      })

      purification.control.forEach(item=>{
        item.value=this.valueMap[item.readDataId] || 0
      })

      return purification
    },
    sandTankList(){
      let list = JSON.parse(JSON.stringify(this.deviceFunction.sandTankList))
      list.forEach(item=>{
        item.switchValue = this.valueMap[item.switchDataId] || 0
        item.statusValue = this.valueMap[item.statusDataId] || 0
        item.chongxiValue = this.valueMap[item.chongxiDataId] || 0
        item.diguanValue = this.valueMap[item.diguanDataId] || 0
      })
      return list
    },
    timingList(){
      let list = JSON.parse(JSON.stringify(this.deviceFunction.timingList))
      list.forEach(item=>{
        for(let key in item){
          if(item[key].dataId){
            item[key].value=this.valueMap[item[key].dataId]
          }
        }
      })
      return list
    },
    calibrationList(){
      let list = JSON.parse(JSON.stringify(this.deviceFunction.calibrationList))
      list.forEach(item=>{
        item.resetValue = this.valueMap[item.resetDataId]

        item.valueArray = []
        for(let i=0;i<item.dataIdArray.length;i++){
          item.valueArray[i] = this.valueMap[item.dataIdArray[i]] || 0
        }
      })
      return list
    },
    systemControl(){
      let data = JSON.parse(JSON.stringify(this.deviceFunction.systemControl))
      //备用泵
      data.standbyPump.value = this.valueMap[data.standbyPump.dataId] || 0
      //设备当前时间
      data.deviceTime.yearValue = this.valueMap[data.deviceTime.yearDataId] || 0
      data.deviceTime.monthValue = this.valueMap[data.deviceTime.monthDataId] || 0
      data.deviceTime.dayValue = this.valueMap[data.deviceTime.dayDataId] || 0
      data.deviceTime.hourValue = this.valueMap[data.deviceTime.hourDataId] || 0
      data.deviceTime.minuteValue = this.valueMap[data.deviceTime.minuteDataId] || 0
      //校正时间
      data.timeSync.enableValue = this.valueMap[data.timeSync.enableDataId] || 0
      data.timeSync.yearValue = this.valueMap[data.timeSync.yearDataId] || 0
      data.timeSync.monthValue = this.valueMap[data.timeSync.monthDataId] || 0
      data.timeSync.dayValue = this.valueMap[data.timeSync.dayDataId] || 0
      data.timeSync.hourValue = this.valueMap[data.timeSync.hourDataId] || 0
      data.timeSync.minuteValue = this.valueMap[data.timeSync.minuteDataId] || 0
      //设备运行时间
      data.deviceRunTime.dayValue = this.valueMap[data.deviceRunTime.dayDataId] || 0
      data.deviceRunTime.hourValue = this.valueMap[data.deviceRunTime.hourDataId] || 0
      //设备锁机日期
      data.deviceLockTime.yearValue = this.valueMap[data.deviceLockTime.yearDataId] || 0
      data.deviceLockTime.monthValue = this.valueMap[data.deviceLockTime.monthDataId] || 0
      data.deviceLockTime.dayValue = this.valueMap[data.deviceLockTime.dayDataId] || 0
      //设备锁机开关
      data.deviceLockStatus.value = this.valueMap[data.deviceLockStatus.dataId] || 0

      return data
    },
    urgentList(){
      let list = JSON.parse(JSON.stringify(this.deviceFunction.urgentList))
      list.forEach(item=>{
        item.value = this.valueMap[item.dataId] || 0
        item.writeValue = this.valueMap[item.writeDataId] || 0
      })
      return list
    }

  },
  created() {
    this.refresh()
  },

  methods: {
    refresh() {
      //获取设备信息
      this.getDeviceInfo()
    },
    onDeviceInfo(){
      if(this.device.model=="ICS_PRO"){
        this.tabKey = "purification"
      }
    },
    editDevice() {
      this.$refs.deviceEdit.show(this.device)
    },

    onTabChange(key) {
      this.tabKey = key
    },

    clickUrgent(item){
      this.control(item.writeDataId,1)
      setTimeout(()=>{
        this.control(item.writeDataId,0)
      },5000)
    }


  }
};
</script>
<style lang="less" scoped>
.device-detail {
  height: 100%;
  display: flex;
  flex-direction: column;

  .body {
    flex-grow: 1;
    padding: 30px 60px;
  }
}

.device-name {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  cursor: pointer;
}

.device-name:hover {
  opacity: 0.5;
}

.batch-control{
  position: absolute;
  margin: 5vw;
  left: 0;
  .item{
    text-align: center;
    img{
      width: 6vw;
      height: 6vw;
    }
    div{
      margin-bottom: 2vw;
      font-size: 1.5vw;
    }
  }
}


</style>
